.stories {
  margin: 90px 0 150px;

  &_slides {
    .slick {
      &-arrow {
        position: absolute;
        font-size: 0;
        color: transparent;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border-radius: 0;
        border: 0;
        margin: 0;
        padding: 0;
        @include bgContain;
        background-size: 9px 18px;
        z-index: 2;
        top: calc(50% - 15px);
        transition: opacity 0.3s $easing;

        &.slick-next {
          right: 0;
          background-image: url(../img/chevRight.svg);
        }

        &.slick-prev {
          left: 0;
          background-image: url(../img/chevLeft.svg);
        }

        &.slick-disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &:not(.slick-disabled) {
          cursor: pointer;
        }
      }
    }

    &-slide {
      padding: 0 9%;
    }
  }
}
