@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');

$lg: 1260px;
$md: 992px;
$tab: 668px;
$sm: 600px;

$basepx: 16px;
$source: 'Source Sans Pro', sans-serif;

$easing: cubic-bezier(.20,.60,.30,1);

$tblack: #090909;
$black: #000;
$white: #fff;
$dwhite: #f9f9f9;
$gray: #bbb;
$lgray: #e5e5e5;
$tgray: #9c9c9c;
$blue: #005ca9;
$dblue: #00549b;
$lblue: #ccdeee;
$autoYellow: #e9f372;
