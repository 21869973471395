.timeline {
  margin: 100px 0;

  &_list {
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.3s $easing;

    .ps {
      &__thumb {
        &-x, &-y {
          background-color: $blue !important;
        }

        &-x {
          height: 6px !important;
        }
      }

      &__rail {
        &-x, &-y {
          background-color: transparent !important;
        }

        &-x {
          height: 6px !important;
        }
      }
    }

    &:not(.show) {
      opacity: 0;
      pointer-events: none;
    }

    &-item {
      width: 110px;
      position: relative;
      flex-shrink: 0;
      display: flex;
      min-height: 170px;

      &:not(:nth-last-child(3)) {
        border-bottom: 1px solid $lgray;
      }

      .point {
        position: absolute;
        left: -12px;
        bottom: -12px;
        height: 24px;
        width: 24px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          background-color: rgba($blue, 0.3);
        }

        &::after {
          content: '';
          position: absolute;
          left: 25%;
          top: 25%;
          height: 50%;
          width: 50%;
          border-radius: 50%;
          background-color: $blue;
        }
      }

      .inner {
        display: flex;
        // height: 100%;
        left: 0;
        width: 200%;
        flex-shrink: 0;
        position: relative;

        .content {
          max-width: 130px;

          h5 {
            font-weight: 300;
            color: $blue;
            position: relative;
            letter-spacing: 0.05em;
            padding-bottom: 13px;
            margin-bottom: 15px;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              height: 1px;
              width: 30px;
              background-color: currentColor;
            }
          }

          p {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.05em;
          }
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          height: calc(100% - 18px);
          width: 1px;
          background: $lgray;
          transform: translateX(-50%);
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          width: 5px;
          height: 5px;
          background-color: $blue;
          transform: translateX(-50%);
          border-radius: 50%;
        }
      }

      &:nth-child(odd) {
        .inner {
          padding: 0 20px 20px;
          align-items: flex-start;

          &::before, &::after {
            top: 0;
          }
        }
      }

      &:nth-child(even) {
        .inner {
          padding: 20px 20px 0;
          transform: translateY(100%);
          align-items: flex-end;

          &::before, &::after {
            bottom: 0;
          }
        }
      }
    }
  }
}
