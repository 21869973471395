.metrology {
  margin: 100px 0;

  &_outer {
    padding: 0 8%;
  }

  &_inner {
    margin-top: 140px;
    padding: 0 14% 70px;
    background-color: $dwhite;

    img {
      width: 100%;
      position: relative;
      top: -70px;
      margin-left: calc(-20% - 60px);
    }

    p, h6 {
      letter-spacing: 0.05em;
      margin: 2em 0;
    }

    > ul {
      margin-top: -1.25em;

      li {
        margin: 0.75em 0;
      }
    }
  }
}
