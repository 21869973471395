.linkSection {
  &_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 50px 0;
    padding: 30px 70px;
    background-color: $dwhite;

    &-photo {
      width: 40%;
      max-width: 330px;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-text {
      width: 60%;
      max-width: 340px;

      h2 {
        font-weight: 400;
      }

      .link {
        color: $blue;
        margin-top: 20px;
      }
    }
  }
}
