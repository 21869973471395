.footer {
  margin: 80px 0 0;
  border-top: 1px solid $lgray;

  &_top {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding-top: 40px;
    padding-bottom: 20px;

    &-list {
      width: calc(25% - 20px);
      margin: 20px 10px;

      h6 {
        color: $blue;
        margin-bottom: 16px;
      }

      ul {
        color: $tgray;
        font-size: 14px;
        line-height: 24px;

        li {
          a {
            display: inline-block;
          }
        }
      }
    }
  }

  &_bot {
    padding: 50px 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      border-top: 1px solid $lgray;
    }

    &-left {
      max-width: calc(50% - 10px);

      .social {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &_link {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $dwhite;

          &:not(:first-child) {
            margin-left: 10px;
          }

          .svg {
            path {
              fill: $gray;
            }

            &_fb {
              width: 6px;
              height: 12px;
            }

            &_lin {
              width: 13px;
              height: 12px;
            }

            &_ins {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      .other {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;

        small {
          font-size: 12px;
          line-height: 18px;
          color: $tgray;

          &:not(:first-child) {
            margin-left: 30px;
          }

          a, span {
            display: inline;
          }

          span {
            margin: 0 10px;
          }
        }
      }
    }

    &-right {
      max-width: calc(50% - 10px);

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
