.article {
  margin: 70px 0;

  &.prod {
    ul li {
      color: $black;
    }
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 1em 0;
    letter-spacing: 0.05em;
  }

  > img, .twoPhotos, .quoteImg, .articleStats, .downloads {
    width: 100%;
    margin: 4em auto;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  blockquote {
    margin: 2em 0;
  }

  .heading.privacy {
    margin-top: 140px;
  }

  &_outer {
    padding: 0 9%;
    overflow: hidden;
  }

  &_inner {
    padding: 0 13%;

    > img, .twoPhotos, .quoteImg, .articleStats, .downloads {
      width: 134%;
      margin: 4em -17%;
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}
