.tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.is_stuck {
    background-color: $white;
    z-index: 2;
  }

  &_tab {
    width: 100%;
    position: relative;

    input {
      display: none;

      &:checked + label {
        color: $blue;

        &::after {
          width: 100%;
        }
      }
    }

    label {
      font-size: 12px;
      line-height: 42px;
      text-transform: uppercase;
      white-space: nowrap;
      font-weight: 600;
      letter-spacing: 0.05em;
      text-align: center;
      cursor: pointer;
      color: $tgray;
      border-bottom: 1px solid $lgray;
      transition: color 0.3s $easing;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        left: 50%;
        transform: translateX(-50%);
        background-color: currentColor;
        transition: width 0.3s $easing;
        width: 0;
      }
    }
  }
}
