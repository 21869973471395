.infos {
  margin: 80px 0;

  &_tabs {
    border: 1px solid $lgray;

    .tabs_tab {
      &:not(:first-child) {
        border-left: 1px solid $lgray;
      }

      label {
        &::after {
          top: -1px;
          height: 2px;
        }

        border: 0;
      }
    }
  }

  &_content {
    border: 1px solid $lgray;
    border-top: 0;
    padding: 6% 6% 9%;

    &-block {
      display: none;
      width: 100%;

      &.active {
        display: block;
      }

      .twoCol {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 50px 0;

        h1, h2, h3, h4, h5, h6 {
          margin: 2em 0 1em;
        }

        p {
          margin: 1em 0;
        }

        li {
          margin: 0.25em 0;
        }

        .col {
          width: 47%;
          margin: 0;
        }
      }

      .table {
        margin: 80px 0;
      }

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}
