.products {
  margin: 140px 0 70px;

  &_catChange {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 50px;

    .select {
      select {
        border-bottom: 0;
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
        color: $black;

        option {
          font-size: 14px;
        }
      }
    }
  }

  &_tabs {
    margin-bottom: 75px;
  }

  &_list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -3px;

    @media (max-width: $md - 1px) {
      width: 100%;
      max-width: 584px;
      margin: 0 auto;
    }

    @media (max-width: $sm - 1px) {
      max-width: 292px;
    }

    &-item, &-sizer {
      width: 25%;

      @media (max-width: $md - 1px) {
        width: 50%;
      }

      @media (max-width: $sm - 1px) {
        width: 100%;
      }
    }

    &-item {
      position: relative;

      .bg {
        background-color: $dwhite;
        position: absolute;
        left: 3px;
        top: 3px;
        width: calc(100% - 6px);
        height: calc(100% - 6px);

        @media (max-width: $md - 1px) {
          left: 6px;
          top: 6px;
          width: calc(100% - 12px);
          height: calc(100% - 12px);
        }
        @include bgContain;
      }

      .sizer {
        width: 100%;
        padding-bottom: 100%;
        opacity: 0;
        pointer-events: none;
      }

      h6 {
        position: absolute;
        left: 0;
        bottom: 36px;
        width: 100%;
        padding: 0 15px;
        text-align: center;
        text-transform: uppercase;
      }

      @media (min-width: $md) {
        &.double-width {
          width: 50%;

          .sizer {
            padding-bottom: 50%;
          }
        }

        &.double-height {
          .sizer {
            padding-bottom: 200%;
          }
        }
      }
    }
  }
}
