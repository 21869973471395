.hero {
  color: $white;

  &_slides {
    position: relative;

    .slick {
      &-arrow {
        position: absolute;
        font-size: 0;
        color: transparent;
        width: 30px;
        height: 15px;
        background-color: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        @include bgContain;
        z-index: 2;
        transition: opacity 0.3s $easing;

        &.slick-next {
          right: 40px;
          bottom: 40px;
          background-image: url(../img/arrowRight.svg);
        }

        &.slick-prev {
          right: 80px;
          bottom: 60px;
          background-image: url(../img/arrowLeft.svg);
        }

        &.slick-disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &:not(.slick-disabled) {
          cursor: pointer;
        }
      }

      &-track {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }

      &-slide {
        float: none;
        height: unset;
        display: flex;
        min-height: 400px;

        > div {
          display: flex;
          width: 100%;
        }
      }
    }

    .paging {
      position: absolute;
      left: 40px;
      bottom: 40px;

      li {
        display: none;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.05em;
      }

      .slick-active {
        display: block;
      }
    }

    > .hero_slides-slide {
      &:not(:first-child) {
        position: absolute;
        top: 100%;
        opacity: 0;
        pointer-events: none;
      }
    }

    &-slide {
      position: relative;
      display: flex !important;
      align-items: flex-end;
      width: 100%;

      .bg {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        @include bgCover;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($black, 0.1);
          background-image: linear-gradient(0deg, rgba($blue, 0.55) 0%, rgba($black, 0) 100%);
          background-size: 100% 100%;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }

      .content {
        position: relative;
        padding: 200px 140px 160px;
        max-width: 770px;

        h5, small {
          text-transform: uppercase;
        }

        .btn {
          margin-top: 20px;
        }
      }
    }
  }
}
