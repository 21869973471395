section.distributors {
  margin-top: 180px;

  @media screen and (max-width: 911px) {
    margin-top: 90px;
  }

  .filter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: calc(760px + 8%);
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 911px) {
      display: block;
    }
  }
  .heading h2 {
    @media screen and (max-width: 911px) {
      font-size: 30px;
      line-height: 36px;
    }
  }
  .container_distributors p {
    @media screen and (min-width: 911px) {
      padding: 0 24%;
      font-size: 18px;
    }
    @media (max-width: 911px) {
      padding: 0 2%;
    }
  }

  .select {
    width: 42%;
    max-width: 380px;
    margin: 0 4%;

    @media screen and (max-width: 911px) {
      width: 100%;
      max-width: 100%;
      margin: 0 0 20px;
    }
  }
  .filter_container {
    transition: opacity 400ms;
    display: flex;
    flex-wrap: wrap;
    margin: 70px auto 0;
    width: 100%;
    max-width: calc(760px + 8%);
    overflow: hidden;

    @media screen and (max-width: 911px) {
      margin-top: 50px;
      max-width: none;
      width: 100%;
      max-width: 100%;
    }

    &.opacity {
      opacity: 0;
    }
  }
  .heading {
    @media screen and (max-width: 911px) {
      margin-bottom: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .distributor {
    display: flex;
    width: 42%;
    max-width: 380px;
    margin: 0 4%;
    margin-bottom: 35px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 35px;
    border-bottom: 1px solid #e5e5e5;
    transition: border-color .3s ease-in-out;

    &:hover {
      border-color: $blue;
    }
    @media screen and (max-width: 911px) {
      width: 100%;
      max-width: 100%;
      margin: 0 0 35px 0;
    }
    .info_holder {
      display: inline-block;
      flex-grow: 1;
    }
    img {
      width: 100px;
      height: 75px;
      object-fit: contain;
      object-position: left center;
      margin-right: 30px;

      @media screen and (max-width: 911px) {
        width: 80px;
        margin-right: 20px;
        height: 60px;
      }
    }
    .name {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .link {
      color: #005ca9;
      text-transform: none;
      display: table;
      padding-bottom: 2px;
      margin-bottom: 5px;
      border: none;
      text-decoration: none;

      &:before {
        display: none;
      }
    }
  }
}

// .distributors {
//   margin-top: 150px;

//   .svg_distr {
//     width: 100%;
//     height: auto;

//     .text, .hover, .point {
//       fill: $blue;
//     }

//     .hover {
//       opacity: 0;
//       transition: opacity 0.3s $easing;
//     }

//     .text {
//       font-size: 10px;
//       font-weight: 600;
//       font-family: $source;
//     }

//     > g {
//       cursor: pointer;

//       &:hover, &.in {
//         .hover {
//           opacity: 0.15;
//         }
//       }
//     }
//   }
// }

// #powerTip {
//   position: absolute;
//   z-index: 999;

//   .pwrtpInner {
//     background-color: $white;
//     box-shadow: 0px 0px 49px 0px rgba($black, 0.11);
//     border-radius: 3px;
//     padding: 15px;
//     max-width: 250px;

//     .logo {
//       width: 100%;
//       height: 40px;
//       @include bgContain;
//       background-position: left center;
//       margin-bottom: 10px;
//     }

//     h6, p {
//       letter-spacing: 0.05em;
//       line-height: 18px;
//     }

//     h6 {
//       font-size: 16px;
//     }

//     p {
//       font-size: 14px;

//       a {
//         color: $blue;
//       }
//     }
//   }
// }
