.colorBlock {
  padding: 6%;
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    margin-right: 6%;
  }

  .btn {
    max-width: 250px;
    min-width: 100px;
    width: 100%;
  }

  &.blue {
    background-color: $blue;
    color: $white;

    .btn {
      background-color: $white;
      color: $blue;
      border-color: $white;
    }
  }

  &.white {
    background-color: $dwhite;
    color: $blue;
  }
}
