.header {
  position: fixed;
  top: 0;
  left: 0;
  color: $tblack;
  background-color: $white;
  width: 100%;
  padding: 0;
  z-index: 10;
  transition: 0.3s $easing;

  &.cntcts {
    background-color: $dwhite;

    &.scrolled {
      background-color: $white;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &_logo {
    width: 136px;
    height: 30px;
    @include bgContain;
    background-image: url(../img/logo.svg);
  }

  &_menu {
    .menu {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-item {
        > a {
          font-weight: 600;
          text-transform: uppercase;
          padding: 35px 30px;
          line-height: 20px;
          font-size: 12px;
          transition: color 0.3s $easing;
        }

        &:hover {
          > a {
            color: $blue;
          }
        }

        &.has-submenu, &.has-mega {
          position: relative;

          &::before {
            content: '';
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid $blue;
            position: absolute;
            left: calc(50% - 4px);
            bottom: 0;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s $easing;
          }

          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }

        &.has-submenu {
          &:hover {
            > .submenu {
              opacity: 1;
              pointer-events: auto;
            }
          }

          > .submenu {
            position: absolute;
            left: 50%;
            top: 100%;
            min-width: 100%;
            transform: translateX(-50%);
            background-color: $blue;
            text-align: center;
            color: $white;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s $easing;
            overflow: hidden;

            > li {
              min-width: 40px;

              &:not(:first-child) {
                position: relative;

                &::before {
                  content: '';
                  display: block;
                  position: absolute;
                  left: calc(50% - 20px);
                  top: 0;
                  width: 40px;
                  height: 1px;
                  background-color: rgba($white, 0.2);
                  z-index: -2;
                }
              }

              > a {
                font-size: 16px;
                line-height: 20px;
                padding: 20px;
                white-space: nowrap;
                position: relative;

                &::after {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  background-color: rgba($dblue, 0);
                  transition: background-color 0.3s $easing;
                  width: 100%;
                  height: calc(100% + 1px);
                  z-index: -1;
                }

                &:hover {
                  &::after {
                    background-color: rgba($dblue, 1);
                  }
                }
              }
            }
          }
        }

        &.has-mega {
          &:hover {
            .mega {
              opacity: 1;
              pointer-events: auto;
            }
          }

          .mega {
            position: fixed;
            width: 100%;
            top: 90px;
            left: 0;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s $easing;

            .footer_top {
              width: 100%;
              margin: 0;
              padding: 40px 60px;
              background: $blue;
            }

            .footer_top-list {
              h6, ul {
                color: $white;
              }

              h6 {
                position: relative;
                padding-bottom: 24px;

                &:after {
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 40px;
                  height: 1px;
                  background-color: rgba($white, 0.2);
                }
              }

              ul {
                margin-top: -5px;

                li a {
                  padding: 5px 10px;
                  margin-left: -10px;
                  background-color: rgba($dblue, 0);
                  transition: background-color 0.3s $easing;

                  &:hover {
                    background-color: rgba($dblue, 1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &_other {
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .searchToggle {
      width: 27px;
      height: 90px;
      cursor: pointer;
      @include bgContain;
      background-image: url(../img/search.svg);
      background-size: 17px 17px;
      margin-right: 20px;

      &.open {
        .searchToggle_form {
          opacity: 1;
          pointer-events: auto;
        }
      }

      &_form {
        position: fixed;
        margin: 0;
        top: 90px;
        width: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s $easing;

        .search_form {
          width: 100%;
          margin: 0;

          input {

            border-radius: 0;
          }
        }
      }
    }

    .langs {
      position: relative;

      &-item {
        font-weight: 600;
        text-transform: uppercase;
        line-height: 20px;
        font-size: 12px;
        padding: 5px 15px;
      }

      &_toggler, &_drop {
        transition: 0.3s $easing;
      }

      &_toggler {
        color: $blue;
        background-color: rgba($white, 0);
        user-select: none;

        &-item {
          position: relative;
          padding: 5px 15px;

          &::after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            right: 5px;
            top: calc(50% - 3px);
            border-top: 3px solid currentColor;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-bottom: 0px solid currentColor;
            transition: border-bottom 0.15s $easing, border-top 0.15s $easing 0.15s;
          }
        }
      }

      &_drop {
        color: $blue;
        background-color: $white;
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        opacity: 0;
        pointer-events: none;
        border-top: 1px solid $blue;
        padding: 5px 0;
      }

      &:hover {
        .langs_drop {
          opacity: 1;
          pointer-events: auto;
        }

        .langs_toggler-item {
          &::after {
            border-top: 0px solid currentColor;
            border-bottom: 3px solid currentColor;
            transition: border-top 0.15s $easing, border-bottom 0.15s $easing 0.15s;
          }
        }
      }
    }
  }
}
