.news {
  margin: 180px 0 0;

  .heading {
    margin-bottom: 100px;
  }

  &_list {
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 50px 0;

      .imageWrap, .textWrap {
        width: calc(50% - 35px);
      }

      .imageWrap {
        .image {
          width: 100%;
          padding-bottom: 64%;
          @include bgCover;
        }
      }

      .textWrap {
        small {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.05em;
          font-weight: 300;

          &.date {
            font-weight: 400;
            color: $tgray;
          }
        }

        h4 {
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
          margin: 0.75em 0;
        }

        .link {
          color: $blue;
          margin-top: 2em;
        }
      }
    }
  }
}
