.twoPhotos {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  .bg {
    position: absolute;
    bottom: 0;
    padding-bottom: 40%;
    width: 100vw;
    z-index: -1;
    left: calc(50% - 50vw);
    background-color: $dwhite;
  }

  .photo {
    width: calc(50% - 20px);
    padding-bottom: 52%;
    margin-bottom: 8%;
    @include bgCover;

    &:nth-child(odd) {
      margin-top: 9%;
    }
  }
}
