h1, h2, h3, h5, h6 {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6, p {
  letter-spacing: 0.025em;
}

h1, h2 {
  font-size: 48px;
  line-height: 60px;
}

h3 {
  font-size: 36px;
  line-height: 42px;
}

h4 {
  font-size: 30px;
  line-height: 36px;
}

h5 {
  font-size: 18px;
  line-height: 22px;
}

h6, p {
  font-size: $basepx;
  line-height: 24px;
}

em, i {
  font-style: italic;
}

b, strong {
  font-weight: 700;
}

sup, sub {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

blockquote {
  display: block;
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.05em;

  &::before {
    content: '';
    display: block;
    margin: 0 auto 0.75em;
    width: em(42px, 30px);
    height: 1em;
    @include bgContain;
    background-image: url(../img/quote.svg);
  }
}

ul:not([class]) {
  color: $tgray;
  font-size: $basepx;
  line-height: 24px;

  li {
    position: relative;
    padding-left: 15px;
    letter-spacing: 0.05em;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $blue;
    }

    ul {
      padding-left: 20px;
      margin: 0;

      li {
        &::before {
          background-color: $lblue;
        }
      }
    }
  }
}
