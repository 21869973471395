.jobs {
  margin: 100px 0;

  &_image {
    width: 100%;
    padding-bottom: 53%;
    position: relative;
    @include bgCover;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.1);
      background-image: linear-gradient(0deg, rgba($blue, 0.55) 0%, rgba($black, 0) 100%);
      background-size: 100% 100%;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &_list {
    margin-top: 60px;
    padding: 0 18%;

    &-item {
      .toggler {
        cursor: pointer;
        padding: 20px 20px 20px 0;
        border-bottom: 1px solid $lgray;
        position: relative;

        h6 {
          line-height: 20px;
          font-weight: 600;
          letter-spacing: 0.05em;
          color: $tgray;
          transition: 0.6s $easing;
        }

        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          right: 2px;
          top: calc(50% - 6px);
          transform-origin: center center;
          border-bottom: 1px solid $tgray;
          border-right: 1px solid $tgray;
          transform: rotate(45deg);
          transition: 0.6s $easing;
        }
      }

      .contentWrap {
        overflow: hidden;
        border-bottom: 1px solid $lgray;
        margin-top: -1px;
        transition: 0.6s $easing;

        .content {
          padding: 25px 0 30px;

          > ul {
            > li {
              margin-top: 0.5em;
            }
          }

          .hint {
            margin: 24px 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .picture {
              width: 90px;
              height: 90px;
              border-radius: 50%;
              margin-right: 30px;
            }

            .text {
              p {
                margin-top: 1em;

                a {
                  color: $blue;
                  text-decoration: underline;
                }
              }

              h6 + p {
                margin-top: 0;
              }
            }
          }

          .btn {
            &.applied {
              opacity: 0.5;
              pointer-events: none;
              position: relative;
            }
          }
        }
      }

      &:not(:first-child) {
        margin-top: 20px;
      }

      &.open {
        .toggler {
          h6 {
            color: $blue;
          }

          &::before {
            top: calc(50% - 2px);
            transform: rotate(-135deg);
          }
        }
      }

      &:not(.open) {
        .contentWrap {
          opacity: 0;
          height: 0 !important;
        }
      }
    }
  }
}
