.related {
  margin: 70px 0;

  .subheading {
    text-align: center;
    margin-bottom: 30px;
  }

  &_slides {
    width: calc(100% - 80px);
    margin-left: 40px;

    .slick {
      &-arrow {
        position: absolute;
        font-size: 0;
        color: transparent;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border-radius: 0;
        border: 0;
        margin: 0;
        padding: 0;
        @include bgContain;
        background-size: 9px 18px;
        z-index: 2;
        top: calc(50% - 15px);
        transition: opacity 0.3s $easing;

        &.slick-next {
          right: -40px;
          background-image: url(../img/chevRight.svg);
        }

        &.slick-prev {
          left: -40px;
          background-image: url(../img/chevLeft.svg);
        }

        &.slick-disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &:not(.slick-disabled) {
          cursor: pointer;
        }
      }
    }

    &-slide {
      padding: 20px;

      .image {
        border: 1px solid $lgray;
        padding-bottom: 108%;
        width: 100%;
        @include bgContain;
      }

      h6 {
        text-align: center;
        padding: 0 20px;
        margin-top: 10px;
      }
    }
  }
}
