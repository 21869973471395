.articleStats {
  display: flex;
  border: 1px solid $lgray;

  &_item {
    width: 100%;

    &:not(:first-child) {
      border-left: 1px solid $lgray;
    }

    &-content {
      max-width: 240px;
      text-align: center;
      margin: 0 auto;
      padding: 60px 30px;

      img {
        height: 60px;
        width: auto;
        display: block;
        margin: 0 auto 10px;
      }

      h2 {
        margin: 0 0 10px !important;
        font-size: 60px;
        color: $blue;
      }

      h6, p {
        margin: 0;
        color: $tgray;
        letter-spacing: 0.05em;
        line-height: 18px;
      }

      p {
        margin-top: 1.5em;
      }

      .btn {
        margin-top: 2em;
        width: 100%;
        min-width: 0;
      }
    }
  }
}
