.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  &_item {
    width: 36px;
    height: 36px;
    border: 1px solid $lgray;
    color: $tgray;
    transition: 0.15s $easing;
    line-height: 34px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 5px;
    border-radius: 2px;

    &.active {
      border-color: $blue;
      color: $blue;
    }
  }

  a.pagination_item {
    &:hover, &:focus {
      border-color: $tgray;
    }
  }
}
