body {
  background-color: $white;
  font-family: $source;
  font-size: 16px;
  line-height: 1.2;
  color: $black;
  font-weight: 400;
  position: relative;

  &.fixed {
    height: 100%;
    overflow: hidden;
  }
}

.container {
  max-width: $lg;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;

  @media (max-width: $md - 1px) {
    padding: 0 20px;
  }
}

main {
  margin-top: 90px;
}

section {
  margin: 50px 0;

  .heading {
    text-align: center;
    margin-bottom: 70px;

    p {
      color: $tgray;
      letter-spacing: 0.05em;
      margin-top: 1em;
    }
  }

  .subheading {
    margin-bottom: 50px;
  }
}

.btn {
  display: inline-block;
  min-width: 220px;
  cursor: pointer;
  background-color: $blue;
  color: $white;
  border: 2px solid $blue;
  line-height: 20px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 600;
  text-align: center;
  padding: 13px;
  border-radius: 2px;
}

.link {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 2px 0;
  border-bottom: 1px solid currentColor;
}

.iframe {
  &-wrap {
    width: 100%;
    position: relative;
    padding-bottom: 52%;
    background-color: $dwhite;
    min-height: 300px;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.slick-slide {
  height: auto;
}
