.modal {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $black;
  display: none;

  &::before {
    content: "";
    width: 100%;
    height: 200%;
    background-color: rgba($black, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .modInner {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2;
    overflow: auto;

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 100px;
      padding-bottom: 100px;

      @media (max-width: $md - 1px) {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      .wide, .slim {
        width: 100%;
        margin: 0 auto;
      }

      .wide {
        max-width: 1100px;
      }

      .slim {
        max-width: 750px;

        .form {
          max-width: 430px;
          margin: 0 auto;
        }
      }

      .contentWrap {
        background-color: $white;
        position: relative;
        width: 100%;
        padding: 50px;

        .close {
          position: absolute;
          right: 0;
          top: 0;
          width: 50px;
          height: 50px;
          background-size: 17px 17px;
          background-position: center center;
          background-repeat: no-repeat;
          background-image: url(../img/cross.svg);
          cursor: pointer;
          z-index: 10;

          @media (max-width: $md - 1px) {
            top: 20px;
            right: 20px;
          }
        }

        .quoteImg {
          align-items: flex-start;

          &_img, &_text {
            width: calc(50% - 20px);
          }
        }
      }
    }
  }
}
