.search {
  margin-top: 180px;
  margin-bottom: 75px;
  min-height: calc(100vh - 700px);

  .container {
    max-width: 730px;
  }

  &_form {
    margin-bottom: 60px;

    @include placeholder() {
      color: rgba($tgray, 0.5);
    }

    form {
      position: relative;

      .input {
        input {
          width: 100%;
          background-color: $dwhite;
          height: 50px;
          font-size: 16px;
          line-height: 30px;
          letter-spacing: 0.025em;
          font-family: $source;
          border: 0;
          border-radius: 3px;
          color: $tgray;
          padding: 0 20px 0 60px;
        }
      }

      .submit {
        position: absolute;
        left: 0;
        top: 0;
        width: 60px;
        height: 50px;

        &::before {
          content: '';
          position: absolute;
          left: 20px;
          top: 15px;
          width: 20px;
          height: 20px;
          @include bgContain;
          background-image: url(../img/search-g.svg);
        }

        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          opacity: 0;
        }
      }
    }
  }

  &_results {
    &-item {
      border-bottom: 1px solid $lgray;

      &:not(:first-child) {
        margin-top: 20px;
      }

      h5 {
        a {
          padding: 9px 0;
          line-height: 30px;
          color: $blue;
        }
      }
    }

    &-message {
      text-align: center;
    }

    .pagination {
      margin-top: 75px;
    }
  }
}
