.select {
  position: relative;

  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    right: 0;
    top: calc(50% - 6px);
    width: 7px;
    height: 7px;
    border-bottom: 2px solid $tgray;
    border-right: 2px solid $tgray;
    transform-origin: center center;
    transform: rotate(45deg);
  }

  select {
    width: 100%;
    height: 45px;
    border: 0;
    border-bottom: 1px solid $lgray;
    font-size: 14px;
    line-height: 24px;
    font-family: $source;
    background-color: transparent;
    border-radius: 0;
    color: $tgray;
    cursor: pointer;
    padding-right: 20px;

    option:disabled {
      opacity: 0.3;
    }
  }
}

.input {
  input {
    width: 100%;
    height: 45px;
    border: 0;
    border-bottom: 1px solid $lgray;
    font-size: 14px;
    line-height: 24px;
    font-family: $source;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    color: $tgray;

    &:-webkit-autofill {
      border-color: $autoYellow;
      -webkit-text-fill-color: $tgray;
      box-shadow: 0 0 0px 1000px $white inset;
      transition: background-color 5000s ease-in-out 0s, border-color 0.3s ease-in-out;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    display: none;

    &:checked + label::after {
      width: 12px;
      opacity: 1;
    }
  }

  label {
    cursor: pointer;
    display: block;
    position: relative;
    line-height: 24px;
    font-size: 14px;
    padding: 3px 0 3px 40px;
    letter-spacing: 0.04em;
    color: $black;
    user-select: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;
      border: 1px solid #ccdeee;
      border-radius: 2px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 8px;
      left: 9px;
      top: 11px;
      background-size: 12px 8px;
      background-image: url(../img/check.svg);
      background-position: 0 0;
      background-repeat: no-repeat;
      opacity: 0;
      width: 0;
      transition: 0.3s $easing;
    }
  }
}

.form {
  &_success {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    max-width: 420px;
    margin: 0 auto;
    padding: 30px 0;

    .image {
      width: 96px;
      height: 96px;
      display: inline-block;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h4 {
      margin-top: 30px;
    }

    p {
      margin-top: 20px;
      color: $tgray;
    }

    .btn {
      margin-top: 40px;
    }

    .link {
      margin-top: 15px;
      color: $blue;
    }
  }

  &_inner {
    @include placeholder() {
      color: rgba($tgray, 0.5);
    }

    .wideInput {
      width: 100%;

      input {
        width: 100%;
      }
    }

    .group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &:not(:first-child) {
        margin-top: 30px;
      }

      &.hideable {
        display: none;
      }
    }

    .hidden {
      display: none;
    }

    h4 {
      text-align: center;
    }

    .checkbox, .input, .select, h4 {
      width: 100%;

      &.half {
        width: 45%;
      }
    }


    .checkbox, .input, .select, h4, .submit {
      &:not(:first-child):not(.half) {
        margin-top: 30px;
      }

      &.half {
        &:not(:nth-child(2)):not(:first-child) {
          margin-top: 30px;
        }
      }
    }
  }
}

.sideForm:not(.btn) {
  border: 1px solid $lgray;
  display: none;

  .form_inner {
    max-width: 430px;
    margin: 0 auto;
    display: block;
  }
}

.requestForm, .sideForm {
  &_form {
    padding: 30px;
  }
}
