.product {
  margin: 110px 0;

  &_wrap {
    padding: 0 7%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &_slides, &_text {
    width: 43%;
  }

  &_slides {
    position: relative;

    &-images, &-thumbs {
      transition: opacity 0.3s $easing;

      &:not(.slick-initialized) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        opacity: 0;
        pointer-events: none;
      }
    }
    &-images {
      .image {
        width: 100%;
        padding-bottom: 100%;
        @include bgContain;
      }
    }

    &-thumbs {
      width: calc(100% - 60px);
      margin-left: 30px;

      .thumb {
        width: 100%;
        padding: 7.5px;

        .inner {
          border: 1px solid $lgray;
          width: 100%;
          padding-bottom: 100%;
          @include bgContain;
        }
      }

      .slick {
        &-current {
          .thumb .inner {
            border-color: $gray;
          }
        }

        &-arrow {
          position: absolute;
          font-size: 0;
          color: transparent;
          width: 30px;
          height: 30px;
          background-color: transparent;
          border-radius: 0;
          border: 0;
          margin: 0;
          padding: 0;
          @include bgContain;
          background-size: 9px 18px;
          z-index: 2;
          top: calc(50% - 15px);
          transition: opacity 0.3s $easing;

          &.slick-next {
            right: -30px;
            background-image: url(../img/chevRight.svg);
          }

          &.slick-prev {
            left: -30px;
            background-image: url(../img/chevLeft.svg);
          }

          &.slick-disabled {
            opacity: 0.5;
            pointer-events: none;
          }

          &:not(.slick-disabled) {
            cursor: pointer;
          }
        }
      }
    }
  }

  &_text {
    &-breadcrumbs {
      border-bottom: 1px solid $lgray;
      padding-bottom: 25px;

      p {
        display: flex;
        font-size: 14px;
        line-height: 20px;
        color: $tgray;

        a {
          display: inline-block;
          position: relative;

          &:not(:first-child) {
            margin-left: 35px;

            &::before {
              content: '';
              pointer-events: none;
              position: absolute;
              left: -23px;
              top: calc(50% - 3.5px);
              width: 7px;
              height: 7px;
              border-top: 2px solid $tgray;
              border-right: 2px solid $tgray;
              transform-origin: center center;
              transform: rotate(45deg);
            }
          }

          &:hover {
            color: $blue;
          }
        }
      }
    }

    &-links {
      display: flex;
      color: $blue;
      margin: 5px -15px 0;
      border-top: 1px solid $lgray;
      padding-top: 25px;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;

      a {
        display: inline-block;
        position: relative;
        margin: 0 15px;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.05em;
        font-weight: 600;
        text-transform: uppercase;

        &:hover {
          color: $blue;
        }
      }
    }

    &-info {
      padding-top: 25px;

      .blocks {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px;

        .block {
          width: calc(50% - 10px);
          margin-bottom: 20px;

          .label {
            font-size: 14px;
            line-height: 18px;
            color: $tgray;
            letter-spacing: 0.04em;
          }

          .value {
            font-size: 18px;
            line-height: 24px;
            color: $blue;
            letter-spacing: 0.04em;
            font-weight: 600;
          }
        }
      }
    }
  }
}
