.inhouse {
  &_list {
    margin-top: -20px;

    &-item {
      margin: 90px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .imageWrap, .textWrap {
        width: calc(50% - 35px);
      }

      .imageWrap {
        .image {
          width: 100%;
          padding-bottom: 90%;
          @include bgCover;
        }

        p {
          font-size: 14px;
          line-height: 24px;
          margin-top: 5px;
          letter-spacing: 0.05em;
          color: $tgray;
        }
      }

      .textWrap {
        > ul {
          margin-top: 1em;
        }
      }

      &:nth-child(odd) {
        .imageWrap {
          order: 1;
        }

        .textWrap {
          order: 2;
        }
      }

      &:nth-child(even) {
        .imageWrap {
          order: 2;
        }

        .textWrap {
          order: 1;
        }
      }
    }
  }
}
