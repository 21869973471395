.qa {
  margin: 130px 0;

  &_list {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0 -25px;

    &-item {
      width: calc(20% - 50px);
      margin: 0 25px;
      text-align: center;

      .icon {
        width: 100%;
        max-width: 120px;
        border: 1px solid $lgray;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
        border-radius: 50%;

        &::before {
          content: '';
          display: block;
          padding-bottom: 100%;
        }

        img {
          position: absolute;
          width: 66%;
          height: 66%;
          left: 17%;
          top: 17%;
        }
      }

      p {
        color: $tgray;
        margin-top: 1em;
        line-height: 24px;
        letter-spacing: 0.025em;
      }

      h6 {
        color: $blue;
        margin-top: 20px;
      }
    }
  }
}
