.follow {
  &_list {
    margin: -3px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    &-item {
      width: calc(#{percentage(1/3)} - 6px);
      margin: 3px;
      position: relative;

      .bg {
        padding-bottom: 100%;
        position: relative;
        @include bgCover;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          z-index: 0;
          height: 100%;
          background-size: 100% 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-image: linear-gradient(0deg, rgba($dwhite, 1) 12.5%, rgba($dwhite, 0) 50%);
        }
      }

      .icon {
        position: absolute;
        width: 36px;
        left: 20px;
        top: 20px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        p {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.05em;
          padding: 25px;

          a {
            display: inline;
            color: $blue;
          }
        }
      }
    }
  }
}
