.team {
  margin: 60px 0 70px;

  &_filters {
    align-items: center;
    justify-content: center;
    padding: 60px 0 0;
    display: none;

    .select {
      width: 42%;
      max-width: 380px;
      margin: 0 4%;
    }
  }

  &_list {
    margin: 70px -10px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    &-item {
      margin: 10px;
      width: calc(25% - 20px);
      position: relative;

      &:hover {
        .bg::after {
          top: -15%;
        }

        .text .links {
          opacity: 1;
          transform: scale(1);
          margin-top: 0;
        }
      }

      .bg {
        width: 100%;
        padding-bottom: 143%;
        @include bgCover;
        position: relative;
        background-color: $dwhite;
        z-index: 0;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 115%;
          background-color: rgba($black, 0.1);
          background-size: 100% 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-image: linear-gradient(0deg, rgba($dwhite, 1) 15%, rgba($dwhite, 0) 50%);
          transition: top 0.5s $easing;
        }
      }

      .text {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        text-align: center;
        padding: 20px;

        h6 {
          text-transform: uppercase;
        }

        small {
          color: $tgray;
          font-size: 14px;
          line-height: 18px;
          margin-top: -4px;
        }

        .links {
          padding-top: 14px;
          opacity: 0;
          transform-origin: center bottom;
          transform: scale(0);
          margin-top: -50px;
          transition: 0.5s $easing;

          a {
            color: $blue;
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}
