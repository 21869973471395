.contacts {
  padding-top: 50px;
  background-color: $dwhite;
  margin-bottom: 0;
  padding-bottom: 80px;

  &_list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      width: percentage(1/3);
      padding: 0 30px;
    }

    &-inner {
      max-width: 210px;

      p {
        margin-top: 1em;
        color: $tgray;

        a {
          color: $blue;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .gmaplink {
        margin-top: 20px;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 24px;
        color: $blue;
        font-weight: 600;
        letter-spacing: 0.025em;
        padding-left: 34px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 24px;
          height: 24px;
          @include bgContain;
          background-image: url(../img/pin.svg);
        }
      }
    }
  }
}
