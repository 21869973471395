.linkBlocks {
  &_grid {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -3px;

    &-item {
      width: calc(100%/3);
      position: relative;
      color: $black;
      transition: color 0.3s $easing;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        border: 3px solid $white;
      }

      .bg {
        width: 100%;
        padding-bottom: 100%;
        z-index: 1;
        position: relative;
        @include bgCover;

        &::before, &::after {
          transition: opacity 0.3s $easing;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $dwhite;
          opacity: 0.95;
          z-index: 1;
        }

        .symbol {
          font-family: $source;
          font-weight: 700;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 350px;
          opacity: 1;
          color: $lgray;
          transition: opacity 0.3s $easing;
          z-index: 2;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($black, 0.1);
          background-image: linear-gradient(0deg, rgba($blue, 0.7) 0%, rgba($blue, 0) 100%);
          background-size: 100% 100%;
          background-position: center center;
          background-repeat: no-repeat;
          opacity: 0;
          z-index: 3;
        }
      }

      .content {
        position: absolute;
        top: 0;
        left: 0;
        padding: 3px;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        small {
          margin-top: 10px;
        }

        h5 {
          text-transform: uppercase;
        }
      }

      &.double {
        width: calc(200%/3);

        .bg {
          padding-bottom: 50%;
        }
      }

      &:hover {
        color: $white;

        .bg {
          &::before {
            opacity: 0;
          }

          &::after {
            opacity: 1;
          }

          .symbol {
            opacity: 0.50;
          }
        }
      }
    }
  }
}
