.header__brochures {
    position: relative;
    z-index: 11;

    .container {

        @media screen and (max-width: $tab) {
            flex-wrap: wrap;
            justify-content: center;
            padding-top: 20px;
        }
    }

    .btn {
        margin: 30px 0px;

        @media screen and (max-width: $tab) {
            width: 100%;
        }
    }
}

.brochures {
    padding: 40px 40px 30px;
    margin-top: 0px;

    @media screen and (max-width: $tab) {
        padding: 0px 20px 30px;
    }

    &Head {
        text-align: center;
        margin-bottom: 40px;
    }

    &Body {

        &__list {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin: -10px;
        }

        &__item {
            width: calc(100%/3);
            position: relative;
            margin-bottom: 20px;

            &--double {
                width: calc(200%/3);

                .brochuresBody__itemImg {
                    padding-bottom: 50%;

                    @media screen and (max-width: $tab) {
                        padding-bottom: 100%;
                    }
                }
            }

            @media screen and (max-width: $tab) {
                width: 100%;
            }

            &Wrap {
            }

            &Img {
                width: 100%;
                padding-bottom: 100%;
                @include bgCover;
                margin-bottom: 25px;
            }

            &Title {
                font-size: 21px;
                text-transform: uppercase;
                letter-spacing: .05em;
                font-weight: 600;
                margin-bottom: 25px;
                padding-left: 10px;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                border: 10px solid #fff;
            }
        }

        &__Submit {
            display: flex;
            margin-top: 50px;

            @media screen and (max-width: $tab) {
                flex-wrap: wrap;
            }

            .input {
                position: relative;
                width: calc(100% - 250px);
                margin-right: 30px;

                @media screen and (max-width: $tab) {
                    width: 100%;
                    margin-bottom: 20px;
                    margin-right: 0px;
                }

                &.full, &.focus {
                    label {
                        opacity: 0.5;
                        transform: scale(0.5) translateY(-60px);
                    }
                }

                label {
                    font-size: 28px;
                    line-height: 24px;
                    position: absolute;
                    left: 0;
                    top: 10px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    transform-origin: left bottom;
                    pointer-events: none;
                    transition: 0.15s cubic-bezier(0.2, 0.6, 0.3, 1);
                    opacity: 1;
                    color: $black;
                    transform: scale(1) translateY(0);
                }

                input {
                    border-color: $black;
                    color: $black;
                    font-size: 28px;
                }
            } 

            .group {

                @media screen and (max-width: $tab) {
                    width: 100%;
                }
            }

            .btn {
                padding: 11px;

                @media screen and (max-width: $tab) {
                    width: 100%;
                }
            }

        }
    }

    &-form {

        &__success {
            position: fixed;
            top: 0px;
            left: 0px;
            background-color: $white;
            height: 100vh;
            width: 100vw;
            z-index: -1;
            opacity: 0;
            transition: .3s ease-in-out;

            &.open {
                z-index: 10;
                opacity: 1;
            }

            .content {
                text-align: center;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }

            .icon {
                margin-bottom: 23px;
            }

            .title {
                font-size: 60px;
                line-height: 60px;
                margin-bottom: 5px;
            }

            .text {
                font-size: 35px;
                letter-spacing: .025em;
                line-height: 24px;
                color: #9C9C9C;
                margin-bottom: 49px;
            }

            .link {
                font-size: 25px;
                color: $blue;
            }
        }
    }
}





.switch {
    position: relative;
    display: inline-block;
    width: 180px;
    height: 37px;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 3;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $lgray;
    border-radius: 37px;
    transition: .4s;

    &::before {
        position: absolute;
        content: "";
        height: 37px;
        width: 37px;
        left: 0px;
        bottom: 0px;
        background-color: white;
        transition: .4s;
        border: 1px solid $blue;
        border-radius: 50%;
        z-index: 2;
    }

    &::after {
        position: absolute;
        content: attr(data-off);
        color: $blue;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        transition: .4s ease-in-out;
        z-index: 1;
    }
}

input:checked + .slider {
    background-color: $blue;

    &::after {
        content: attr(data-on);
        color: $white;
    }
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    transform: translateX(143px);
}