.page404 {
  margin-top: 170px;
  margin-bottom: 80px;
  min-height: calc(100vh - 700px);
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .container {
    img {
      height: 80px;
      width: auto;
      display: inline-block;
      margin-bottom: 30px;
    }

    h2 {
      margin-bottom: 20px;
    }

    p {
      letter-spacing: 0.05em;
      font-weight: 300;
      color: $tgray;
      margin-bottom: 20px;
    }

    .link {
      color: $blue;
    }
  }
}
