.table {
  position: relative;

  table {
    min-width: 700px;
  }

  .ps {
    &__thumb {
      &-x, &-y {
        background-color: $blue !important;
      }

      &-x {
        height: 6px !important;
      }
    }

    &__rail {
      &-x, &-y {
        background-color: transparent !important;
      }

      &-x {
        height: 6px !important;
      }
    }
  }
}

.framelessTable {
  h6 {
    margin-bottom: 2em;
  }

  table {
    width: 100%;
    font-size: 14px;
    font-family: $source;
    line-height: 18px;

    &, th, td {
      border-collapse: collapse;
    }

    th, td {
      padding: 1em 0.8em;
      text-align: left;
      vertical-align: top;
    }

    tbody {
      th, td {
        border-top: 1px solid $lgray;
      }
    }

    th {
      font-weight: 600;
    }
  }
}

.framedTable {
  h6 {
    margin-bottom: 2em;
  }

  table {
    width: 100%;
    font-size: 14px;
    font-family: $source;
    line-height: 18px;

    &, th, td {
      border: 1px solid $lgray;
      border-collapse: collapse;
    }

    th, td {
      padding: 1em 0.8em;
      text-align: center;
    }

    th {
      vertical-align: baseline;
      font-weight: 600;
      background-color: $dwhite;
      padding-top: 2.5em;
    }

    td {
      vertical-align: middle;
      font-weight: 400;
    }
  }
}
