.quoteImg {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.right {
    .quoteImg_img {
      order: 2;
    }
    .quoteImg_text {
      order: 1;
    }
  }

  &_img {
    width: 42%;

    img {
      width: 100%;
    }
  }

  &_text {
    width: 50%;

    blockquote {
      text-align: left;
      margin: 0;

      &::before {
        margin: 0 0 0.75em;
      }
    }

    &-credits {
      border-top: 1px solid $lgray;
      margin-top: 20px;
      padding-top: 20px;

      h6 {
        font-weight: 400;
        letter-spacing: 0.05em;
        margin: 0 !important;
      }

      small {
        font-size: 14px;
        color: $tgray;
        letter-spacing: 0.05em;
      }

      .btn {
        min-width: 150px;
        margin: 2em 0 0;
      }
    }

    &-questions {
      margin-top: 40px;

      h5 {
        font-size: 24px;
        margin-top: 1em;
      }

      p {
        margin-top: 0.25em;
        color: $tgray;
      }
    }
  }
}
