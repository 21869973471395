.alerts {
  margin: 150px 0;

  &_list {
    padding: 0 9%;

    .articleStats {
      border: 1px solid $lgray;
      
      &_item {
        position: relative;

        &:not(:first-child) {
          &::before {
            content: 'or';
            position: absolute;
            right: 100%;
            top: 40%;
            background-color: $white;
            line-height: 60px;
            font-size: 16px;
            color: $tgray;
            transform: translate(50%, -50%);
            letter-spacing: 0.05em;
          }
        }
      }
    }

    &-link {
      margin-top: 50px;
    }
  }
}
