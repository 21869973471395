.innerHero {
  margin: 70px 0;

  &_content {
    position: relative;
    color: $white;
    padding: 140px 9%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.left {
      align-items: flex-start;
    }

    &.right {
      align-items: flex-end;
    }

    &.center {
      align-items: center;
    }

    &-bg {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      @include bgCover;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($blue, 0.1);
        background-image: linear-gradient(0deg, rgba($blue, 0.55) 0%, rgba($blue, 0) 100%);
        background-size: 100% 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }

    .back {
      position: relative;
      padding-left: 30px;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.05em;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 20px;

      &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 15px;
        left: 0;
        top: 2.5px;
        background-image: url(../img/arrowLeft.svg);
        background-size: cover;
        background-position: left center;
        background-repeat: no-repeat;
      }
    }

    h4 {
      max-width: 530px;
      font-weight: 600;
    }
  }
}
