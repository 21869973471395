.special {
  margin: 100px 0;

  &_cycle {
    max-width: 900px;
    margin: -50px auto 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-item {
      width: 40%;
      text-align: center;
      margin: 50px 5%;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        width: 1px;
        height: 50px;
        background-color: $lgray;
        top: -75px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 60px;
        width: 20%;
        height: 1px;
        background-color: $lgray;
      }

      &:nth-child(1), &:nth-child(2) {
        &::before {
          display: none;
        }
      }

      &:nth-child(odd) {
        &::after {
          left: 100%;
        }
      }

      &:nth-child(even) {
        &::after {
          right: 100%;
        }
      }

      .icon {
        width: 100%;
        max-width: 120px;
        border: 1px solid $lgray;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
        border-radius: 50%;

        &::before {
          content: '';
          display: block;
          padding-bottom: 100%;
        }

        img {
          position: absolute;
          width: 66%;
          height: 66%;
          left: 17%;
          top: 17%;
        }
      }

      p {
        color: $tgray;
        margin-top: 0.5em;
        line-height: 24px;
        letter-spacing: 0.025em;
      }

      h6 {
        color: $blue;
        margin-top: 20px;
      }
    }
  }
}
