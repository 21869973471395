.downloads {
  .download {
    margin: 30px auto;
    max-width: 384px;
    width: 100%;
    border: 1px solid $lgray;
    color: $tgray;
    padding: 13px 20px 13px 54px;
    line-height: 20px;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    display: block;
    transition: border-color 0.15s $easing;

    &:hover {
      border-color: $tgray;
    }

    &::before {
      content: '';
      width: 20px;
      height: 26px;
      top: 10px;
      left: 20px;
      position: absolute;
      pointer-events: none;
      background-image: url(../img/pdf.svg);
      @include bgContain;
    }
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}
